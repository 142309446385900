<template>
  <div class="TeslimFisi">
    <b-alert v-if="userData.silindimi" variant="danger" show>
      <h4 class="alert-heading">
        Silinmiş
      </h4>
      <div class="alert-body">
        <span>Bu teslimat silinmiştir. Silinme Tarihi : {{ userData.silTarihi }}</span>
      </div>
    </b-alert>
    <b-card>
      <b-row>
        <b-col cols="21" xl="6" class="d-flex justify-content-between flex-column">
          <div class="d-flex justify-content-start">
            <b-avatar
                :src="userData.soforAvatar"
                :text="avatarText(userData.sofor)"
                variant="light-success"
                size="154px"
                rounded/>
            <div class="d-flex flex-column ml-1 mt-5">
              <div class="mb-1">
                <h4 class="mb-0">
                  {{ userData.sofor }}
                </h4>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center mt-2">
            <div class="d-flex align-items-center mr-2">
              <b-avatar variant="light-primary" rounded>
                <feather-icon icon="MapPinIcon" size="18"/>
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">
                  <a :href="userData.konumLink" target="_blank"> Göster</a>
                </h5>
                <small>Konum</small>
              </div>
            </div>
          </div>
        </b-col>
        <b-col cols="12" xl="6">
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon icon="HashIcon" class="mr-75"/>
                <span class="font-weight-bold">Fiş Numarası</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ userData.fisNo }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="PackageIcon" class="mr-75"/>
                <span class="font-weight-bold">Birim</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ userData.birim }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="CalendarIcon" class="mr-75"/>
                <span class="font-weight-bold">Fiş Tarihi</span>
              </th>
              <td class="pb-50">
                {{ userData.tarih }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75"/>
                <span class="font-weight-bold">Durumu</span>
              </th>

              <td class="pb-50">
                <b-badge v-if="userData.silindimi" pill variant="danger">Silindi</b-badge>
                <b-badge v-else pill variant="success">Aktif</b-badge>
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-row>
        <b-table :items="userData.urunler"/>
      </b-row>
    </b-card>
    <hr class="mb-5">
    <b-row>
      <b-col v-if="!userData.kase && userData.imza" offset="3" cols="6">
        <h3>İmza</h3>
        <b-card img-alt="Profile Cover Photo" img-top class="card-profile">
          <div class="profile-image-wrapper">
            <div class="profile-image p-0">
              <b-avatar rounded size="114" variant="light" :src="`data:image/png;base64,${userData.imza}`"/>
            </div>
          </div>
          <b-button @click="openBase64InNewTab(userData.imza,'image/png');" variant="primary">{{
              userData.teslimAlan
            }}
          </b-button>
        </b-card>
      </b-col>
      <b-col v-if="userData.kase && userData.imza" cols="6">
        <h3>İmza</h3>
        <b-card img-alt="Profile Cover Photo" img-top class="card-profile">
          <div class="profile-image-wrapper">
            <div class="profile-image p-0">
              <b-avatar rounded size="114" variant="light" :src="`data:image/png;base64,${userData.imza}`"/>
            </div>
          </div>
          <b-button @click="openBase64InNewTab(userData.imza,'image/png');" variant="primary">{{
              userData.teslimAlan
            }}
          </b-button>
        </b-card>
      </b-col>
      <b-col cols="6" v-if="userData.kase">
        <h3>Kaşe</h3>
        <b-card img-alt="Kase" img-top class="card-profile">
          <div class="profile-image-wrapper">
            <div class="profile-image p-0">
              <b-avatar rounded size="114" variant="light" :src="`data:image/png;base64,${userData.kase}`"/>
            </div>
          </div>
          <b-button @click="openBase64InNewTab(userData.kase,'image/png');" variant="primary">Göster</b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {avatarText} from '@core/utils/filter'
import {dangerToast, successToast} from "@/views/Services/ToastServices";
import {set, deleteCokie, checkCookie} from "@/views/Services/CookieJob";
import store from "@/store";
import {getItems} from "@/views/Services/CartUpdate";

export default {
  name: 'Teslimfisi',
  async mounted() {
    try {
      let veri = await this.$http.get("TeslimFisiGosterV1/" + this.$route.params.id)
      this.userData = veri.data;
      await this.login();
    } catch (e) {
      dangerToast(this, "Hata", "Teslim fişi bilgileri alınamıyor,lütfen bir daha deneyin.")
    }

  },
  data() {
    return {
      avatarText,
      userData: []
    }
  },
  methods: {
    openBase64InNewTab(data, mimeType) {
      let byteCharacters = atob(data);
      let byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      let file = new Blob([byteArray], {type: mimeType + ';base64'});
      let fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    },
    async login() {
      try {
        let veri = await this.$http.get("TeslimFisiGirisV1/" + this.$route.params.id);
        if (veri.data["status"] == true) {
          successToast(this, "Başarılı", "Giriş işleminiz gerçekleşti");
          set("guid", veri.data.guid, 1);
          await getItems(veri.data.guid);
          store.commit('loginStoreModule/SET_USER_BILGILERI', veri)
        }
        if (veri.data["status"] == false || veri.data == false) dangerToast(this, "Hata", "Giriş işleminiz gerçekleşemedi")

      } catch (e) {
        dangerToast(this, "Hata", "İçsel bir hata ile karşılaşıldı")
        deleteCokie();
      }

    }

  }
};
</script>
